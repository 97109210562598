<template>
	<div class='a-settings-mark-up'>
		<div class='a-settings-mark-up-item' v-for='(item, i) in items' :key='i'>
			<p class='a-settings-mark-up-title'>{{item.title}}</p>
			<div class="row a-settings-mark-up-row">
				<div class="col a-settings-mark-up-col">
					<a-select
						:label='$t("account.settings.margin_type")'
						:placeholder='$t("account.settings.margin_type_check")'
						:options='type_items'
						v-model='item.type'
						small
						class='a-settings-mark-up-select'
					/>
				</div>
				<div class="col a-settings-mark-up-col">
					<v-text-field
						:label='$t("account.settings.size_markup")'
						:placeholder='$t("account.settings.size_markup_check")'
						:name='`price-${i}`'
						type='number'
						v-model='item.amount'
						hide-details
						class='a-settings-mark-up-input'
					/>
				</div>
				<div class="col-auto a-settings-mark-up-col">
					<div class='a-settings-mark-up-button'>
						<v-button xxsmall color='green white--text'
							@click='changeMargin(item)'
							:loading='item.loading'
						>{{$t("account.buttons.save")}}</v-button>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import { i18n } from '@/plugins/i18n';
	import { updateUserMargin, user } from "@/services/request";

	export default {
		data: () => ({
			items: []
		}),
		computed: {
			type_items() {
				return [
					{id: "percent", name: i18n.t("account.form.label.percent")},
					{id: "amount", name: i18n.t("account.form.label.specific_amount")}
				]
			}
		},
		mounted() {
			if(this.$store.state.auth.user?.margins) {
				this.initialItems();
			}
		},
		methods: {
			initialItems() {
				const initialItems = [
					{key: "flight", title: `${i18n.t("account.itinerary.markup")} ${i18n.t("routes.flights")}`, type: null, amount: null, loading: false},
					{key: "hotel", title: `${i18n.t("account.itinerary.markup")} ${i18n.t("routes.hotels")}`, type: null, amount: null, loading: false},
					{key: "transfer", title: `${i18n.t("account.itinerary.markup")} ${i18n.t("routes.transfers")}`, type: null, amount: null, loading: false},
					// {key: "rentTransfer", title: `${i18n.t("account.itinerary.markup")} ${i18n.t("routes.cars")}`, type: null, amount: null, loading: false},
					{key: "charterFlight", title: `${i18n.t("account.itinerary.markup")} ${i18n.t("routes.charter-flight_short")}`, type: null, amount: null, loading: false},
					{key: "insurance", title: `${i18n.t("account.itinerary.markup")} ${i18n.t("routes.insurance")}`, type: null, amount: null, loading: false},
					{key: "visa", title: `${i18n.t("account.itinerary.markup")} ${i18n.t("routes.visa")}`, type: null, amount: null, loading: false}
				]

				this.items = initialItems.map(el => {
					let findMargin = this.$store.state.auth.user.margins[el.key];
					if(findMargin) {
						return {
							...el,
							type: findMargin.type,
							amount: findMargin.amount
						}
					} else {
						return el
					}
				})
			},
			changeMargin(item) {
				item.loading = true;

				updateUserMargin({
					type: item.type,
					service: item.key,
					amount: item.amount
				}).then(() => {
					user().then(res => {
						this.$store.commit("auth/user", res.data);
						this.initialItems();
					});

					this.$store.commit('notification/successMessage', {title: this.$t("account.settings.changes_saved")});
				}).catch(() => {
					item.loading = false;
				})
			}
		},
		watch: {
			"$store.state.auth.user"() {
				this.initialItems();
			}
		}
	}
</script>


<style lang="scss">
	@import "@/styles/global/variables";
	@import "@/styles/global/mixins";

	.a-settings-mark-up {
		&-item {
			&:not(:last-child) {
				margin-bottom: 50px;
				@include minw( $grid-breakpoints-xs ) {
					margin-bottom: 35px;
				}
			}
		}
		&-title {
			font-size: 16px;
			font-weight: 700;
			margin-bottom: 15px;
		}
		&-row {
			@include minw( $grid-breakpoints-xs ) {
				margin: 0 -8px;
			}
		}
		&-col {
			@include minw( $grid-breakpoints-xs ) {
				padding: 0 8px;
			}
			@include maxw( $grid-breakpoints-xs - 1 ) {
				flex: 1 1 100%;
			}
		}
		&-select {
			@include maxw( $grid-breakpoints-xs - 1 ) {
				margin-bottom: 25px;
			}
		}
		&-input {
			margin-bottom: 0;
		}
		&-button {
			margin-top: 23px;
			.app-btn {
				min-width: 119px;
				@include maxw( $grid-breakpoints-xs - 1 ) {
					width: 100%;
				}
			}
		}
	}
</style>
