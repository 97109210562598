<template>
	<div>
		<div class='a-settings'>
			<div class="app-container a-settings-container">
				<p class="a-heading-1">{{$t("routes.settings")}}</p>
				<div class='a-settings-content'>
					<div v-for="(item, i) in items" :key='i'>
						<v-accordion hideTriggerIcon>
							<template v-slot:title>
								<v-icon icon='arrow-down' :size='14' />
								{{ item.title }}
							</template>

							<template v-slot:content>
								<component :is='item.component' />
							</template>
						</v-accordion>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import MarkUp from '@/views/account/settings/mark-up';
	import EditProfile from '@/views/account/settings/edit-profile';
	import Logo from '@/views/account/settings/logo';
	import Choose from '@/views/account/settings/choose';
	import { i18n } from '@/plugins/i18n';

	export default {
		data: () => ({
			Accordion: {
				count: 0,
				active: null
			}
		}),
		computed: {
			items() {
				return [
					{title: i18n.t("account.itinerary.markup"), component: MarkUp},
					{title: i18n.t("account.settings.edit_profile"), component: EditProfile},
					{title: i18n.t("account.settings.logo_company"), component: Logo},
					{title: i18n.t("account.settings.choose_lang_and_currency"), component: Choose}
				]
			},
		},
		provide() {
			return { Accordion: this.Accordion }
		}
	}
</script>

<style lang="scss">
	@import "@/styles/global/variables";
	@import "@/styles/global/mixins";

	.a-settings {
		padding: 30px 0 80px;
		display: grid;
		@include minw( $grid-breakpoints-sm ) {
			padding: 70px 0;
		}
		&-container {
			max-width: 925px;
		}
		.a-heading-1 {
			margin-bottom: 15px;
			@include maxw( $grid-breakpoints-xs - 1 ) {
				margin-bottom: 25px;
				text-align: center;
			}
		}
		&-content {
			.app-accordion {
				&__title {
					padding: 15px 0;
					@include minw( $grid-breakpoints-xs ) {
						padding: 20px 0;
					}
					.heading-4 {
						display: flex;
						align-items: center;
						font-weight: 400;
						@include minw( $grid-breakpoints-lg ) {
							font-size: 18px;
						}
						.app-icon {
							flex-shrink: 0;
							margin-right: 25px;
							color: $green-persian;
							transition: transform $transition;
						}
					}
				}
				&__trigger_active {
					.app-icon {
						transform: rotate(180deg);
					}
				}
				&__content {
					padding: 15px 0 30px;
					border: none;
					@include minw( $grid-breakpoints-xs ) {
						padding: 20px 0 40px 40px;
					}
				}
				&__item {
					border-bottom: 1px solid rgba($black, .1);
					border-radius: 0;
					margin-bottom: 0;
				}
			}
		}
	}
</style>
