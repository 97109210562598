<template>
	<div class='a-settings-edit-profile'>
		<v-text-field
			:placeholder='$t("account.settings.add_your_query")'
			name='textarea'
			type='textarea'
			v-model='text'
			:rows='5'
		/>
		<div class='a-settings-edit-profile-btn'>
			<v-button block xxsmall color='green white--text'
				@click='dataEditUser'
				:loading='loading_btn'
				:disabled='!text.length'
			>{{$t("account.buttons.send")}}</v-button>
		</div>

		<dialog-success
			:title="$t('account.messages.success.your_request_has_been_sent_successfully')"
			:text="$t('account.messages.success.our_manager_will_contact_you_in_the_near_future')"
			v-model='success'
			@close-dialog="success = false"
		/>
	</div>
</template>

<script>
	import DialogSuccess from '@/views/account/home/components/DialogSuccess';
	import { dataEditUser } from '@/services/request';

	export default {
		data: () => ({
			text: '',
			loading_btn: false,
			success: false
		}),
		components: {
			DialogSuccess
		},
		methods: {
			dataEditUser() {
				this.loading_btn = true;
				dataEditUser({ text: this.text })
					.then(() => this.success = true, this.text = '')
					.finally(() => this.loading_btn = false);
			}
		}
	}
</script>

<style lang="scss">
	@import "@/styles/global/variables";
	@import "@/styles/global/mixins";

	.a-settings-edit-profile {
		&-btn {
			@include minw( $grid-breakpoints-xs ) {
				max-width: 125px;
			}
		}
	}
</style>
