<template>
	<div class='a-settings-logo'>
		<div class="row a-settings-logo-row">
			<div class="col-auto a-settings-logo-col">
				<v-file-upload
					:title='$t("account.settings.add_image")'
					:maxSize='2000'
					accept='image/png,image/jpg,image/jpeg'
					:hint='!logo ? $t("account.settings.format") : null'
					:avatar='logo'
					@update-files='logo = $event'
					@remove-avatar='deleteLogoUser'
				/>
			</div>
			<div class="col-auto a-settings-logo-col" v-if='!($store.state.auth.user && $store.state.auth.user.logo)'>
				<v-button xxsmall color='green white--text' class='a-settings-logo-button'
					@click='changeLogo'
					:loading='loading_logo'
				>{{$t("account.buttons.save")}}</v-button>
			</div>
		</div>
	</div>
</template>

<script>
	import VFileUpload from '@/components/VFileUpload';
	import { updateUser, deleteLogoUser } from "@/services/request";

	export default {
		data: () => ({
			logo: "",
			loading_logo: false
		}),
		components: {
			VFileUpload
		},
		mounted() {
			if(this.$store.state.auth.user?.logo) {
				this.logo = this.$store.state.auth.user.logo;
			}
		},
		methods: {
			async changeLogo() {
				this.loading_logo = true;

				updateUser({ logo: this.logo }).then(res => {
					this.$store.commit("auth/user", res.data);
					this.$store.commit('currency', this.currency);
					this.$store.commit('notification/successMessage', {title: this.$t("account.settings.changes_saved")});

				}).finally(() => this.loading_logo = false)
			},
			deleteLogoUser() {
				deleteLogoUser().then((res) => {
					this.logo = "";
					this.$store.commit("auth/user", res.data);
				});
			}
		},
		watch: {
			"$store.state.auth.user"() {
				this.logo = this.$store.state.auth.user?.logo ?? "";
			}
		}
	}
</script>


<style lang="scss">
	@import "@/styles/global/variables";
	@import "@/styles/global/mixins";

	.a-settings-logo {
		&-row {
			@include minw( $grid-breakpoints-xs ) {
				margin: 0 -15px;
			}
		}
		&-col {
			@include minw( $grid-breakpoints-xs ) {
				padding: 0 15px;
			}
			@include maxw( $grid-breakpoints-xs - 1 ) {
				flex: 1 1 100%;
			}
		}
		&-button {
			min-width: 125px;
			@include maxw( $grid-breakpoints-xs - 1 ) {
				width: 100%;
				margin-top: 25px;
			}
		}
	}
</style>
