<template>
	<div class='a-settings-choose'>
		<div class="row a-settings-choose-row">

			<div class="col-auto a-settings-choose-col">
				<div class="row a-settings-choose-item-row">
					<div class="col-auto a-settings-choose-item-col">
						<a-select
							:label='$t("account.settings.interface_language")'
							:placeholder='$t("account.booking.content.choose")'
							:options='locales'
							item-value='code'
							v-model='language'
							small
							class='a-settings-choose-item-select'
						/>
					</div>
					<div class="col-auto a-settings-choose-item-col">
						<div class="a-settings-choose-item-button">
							<v-button xxsmall color='green white--text'
								@click='changeLanguage(language)'
								:loading='loading_language'
							>{{$t("account.buttons.save")}}</v-button>
						</div>
					</div>
				</div>
			</div>

			<div class="col-auto a-settings-choose-col">
				<div class="row a-settings-choose-item-row">
					<div class="col-auto a-settings-choose-item-col">
						<a-select
							:label='$t("account.settings.currency_selection")'
							:placeholder='$t("account.booking.content.choose")'
							:options='exchange_rates'
							item-value='code'
							v-model='currency'
							small
							class='a-settings-choose-item-select'
						/>
					</div>
					<div class="col-auto a-settings-choose-item-col">
						<div class="a-settings-choose-item-button">
							<v-button xxsmall color='green white--text'
								@click='changeCurrency'
								:loading='loading_currency'
							>{{$t("account.buttons.save")}}</v-button>
						</div>
					</div>
				</div>
			</div>

		</div>
	</div>
</template>

<script>
	import LanguageAndCurrencyMixin from '@/mixins/language-and-currency-mixin'

	export default {
        mixins:[LanguageAndCurrencyMixin],
	}
</script>


<style lang="scss">
	@import "@/styles/global/variables";
	@import "@/styles/global/mixins";

	.a-settings-choose {
		&-row {
			justify-content: space-between;
			margin-top: -15px;
			margin-bottom: -15px;
			@include maxw( $grid-breakpoints-xs - 1 ) {
				flex-direction: column;
			}
		}
		&-col {
			padding-top: 15px;
			padding-bottom: 15px;
		}
		&-item {
			&-row {
				align-items: center;
				margin: 0 -8px;
			}
			&-col {
				padding: 0 8px;
				@include maxw( $grid-breakpoints-xs - 1 ) {
					flex: 1 1 100%;
				}
			}
			&-select {
				@include minw( $grid-breakpoints-xs ) {
					width: 250px;
				}
			}
			&-button {
				margin-top: 23px;
				.app-btn {
					min-width: 119px;
					@include maxw( $grid-breakpoints-xs - 1 ) {
						width: 100%;
					}
				}
			}
		}
	}
</style>
